// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/studio/ProjectApp/4Projects/EasyRentCloud/KunShungStatic/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("/Users/studio/ProjectApp/4Projects/EasyRentCloud/KunShungStatic/src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-contactus-js": () => import("/Users/studio/ProjectApp/4Projects/EasyRentCloud/KunShungStatic/src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-en-aboutus-js": () => import("/Users/studio/ProjectApp/4Projects/EasyRentCloud/KunShungStatic/src/pages/en/aboutus.js" /* webpackChunkName: "component---src-pages-en-aboutus-js" */),
  "component---src-pages-en-contactus-js": () => import("/Users/studio/ProjectApp/4Projects/EasyRentCloud/KunShungStatic/src/pages/en/contactus.js" /* webpackChunkName: "component---src-pages-en-contactus-js" */),
  "component---src-pages-en-index-js": () => import("/Users/studio/ProjectApp/4Projects/EasyRentCloud/KunShungStatic/src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-information-js": () => import("/Users/studio/ProjectApp/4Projects/EasyRentCloud/KunShungStatic/src/pages/en/information.js" /* webpackChunkName: "component---src-pages-en-information-js" */),
  "component---src-pages-en-products-js": () => import("/Users/studio/ProjectApp/4Projects/EasyRentCloud/KunShungStatic/src/pages/en/products.js" /* webpackChunkName: "component---src-pages-en-products-js" */),
  "component---src-pages-index-js": () => import("/Users/studio/ProjectApp/4Projects/EasyRentCloud/KunShungStatic/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-information-js": () => import("/Users/studio/ProjectApp/4Projects/EasyRentCloud/KunShungStatic/src/pages/information.js" /* webpackChunkName: "component---src-pages-information-js" */),
  "component---src-pages-products-js": () => import("/Users/studio/ProjectApp/4Projects/EasyRentCloud/KunShungStatic/src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/studio/ProjectApp/4Projects/EasyRentCloud/KunShungStatic/.cache/data.json")

